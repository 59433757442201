import { CalculatedData } from '@/classes/stats/Calculations/CalculatedData'

export class VoiceCalculatedData extends CalculatedData {
  // eslint-disable-next-line complexity
  calculate (campaignDataSource) {
    const additional = JSON.parse(JSON.stringify(campaignDataSource.additional))
    if (!campaignDataSource.total) {
      return
    }
    this.totals = additional[0]

    this.totals.fixed_sent_total = this.calculateTotalCalls(this.totals)
    this.totals.fixed_undelivered_total = this.calculateUndeliveredTotal(this.totals)

    this.totals.delivered_total = parseInt(this.totals.delivered_total)

    const totalCalls = this.totals.delivered_total + this.totals.fixed_undelivered_total
    this.undeliveredPercentage = (totalCalls && totalCalls !== 0) ? ((this.totals.fixed_undelivered_total / totalCalls) * 100) : 0
    this.undeliveredPercentage = this.undeliveredPercentage ? this.undeliveredPercentage : 0

    this.deliveredPercentage = (this.totals.fixed_sent_total === 0 ? this.totals.fixed_sent_total : ((this.totals.delivered_total / totalCalls) * 100)).toFixed(2)
    this.deliveredPercentage = this.deliveredPercentage ? this.deliveredPercentage : 0

    const unsubscribedPercentage = this.totals.delivered_total
      ? (this.totals.unsubscribed_total / this.totals.delivered_total) * 100
      : 0
    this.unsubscribedPercentage = unsubscribedPercentage > 0 ? unsubscribedPercentage.toFixed(0) : '0'
    this.unsubscribedTotal = parseInt(this.totals.unsubscribed_total)
  }

  calculateTotalCalls (data) {
    const total = parseInt(data.total_api) + parseInt(data.total_no_api)
    return total || 0
  }

  calculateUndeliveredTotal (data) {
    const total = this.calculateTotalCalls(data) - data.delivered_total
    return total || 0
  }
}
