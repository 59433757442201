import { Service } from './service'

const PATH = 'stats/general/'

class GeneralStatsService extends Service {
  getFunnelStats (params = {}) {
    return this.get(PATH + 'funnelStats', params)
  }

  getimpactCostStats (params = {}) {
    return this.get(PATH + 'impactCostStats', params)
  }

  getImpactDaysStats (params = {}) {
    return this.get(PATH + 'impact-days-stats')
  }

  getImpactDaysStatsVoiceCampaign (params = {}) {
    return this.get(PATH + 'impact-days-stats-voice-campaign', params)
  }

  getImpactCostStatsWhatsApp (params = {}) {
    return this.get(PATH + 'whatsapp/impactCostStats', params)
  }
}

export default new GeneralStatsService()
