import { Service } from './service'

const PATH = 'stats/voice/'
const SHARE_PATH = 'share/stats/voice/'
class VoiceStatsService extends Service {
  getChannelDatatable (params = {}) {
    return this.get(PATH + 'channel/datatable', params)
  }

  getCampaignDatatable (params = {}) {
    return this.get(PATH + 'campaign-datatable', params)
  }

  getCampaignData (campaignId) {
    return this.get(PATH + 'campaign/' + campaignId)
  }

  getShareCampaignData (campaignId, shareHash) {
    return this.get(SHARE_PATH + 'campaign/' + campaignId + '?shareHash=' + shareHash)
  }

  getShareCampaignDatatable (params = {}, shareHash) {
    return this.get(SHARE_PATH + 'campaign-datatable' + '?shareHash=' + shareHash, params)
  }

  getContactPhonesByPressedKey (channelId, key) {
    return this.get(PATH + 'contact-phones-by-pressed-key/' + channelId + '/' + key)
  }

  getImpactedTotalBySendingId (params) {
    return this.post(PATH + 'impacted-total-by-sending', params)
  }
}

export default new VoiceStatsService()
