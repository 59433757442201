import getEnv from '@/util/env'
import VueApexCharts from 'vue-apexcharts'
import getSymbolFromCurrency from 'currency-symbol-map'
import { GlobalControl } from '@/classes/stats/GlobalControl.js'
import { ExportForm } from '@/classes/ExportForm'
import { ImpactCost } from '@/classes/stats/ImpactCost.js'
import VoiceStatsService from '@/services/voice-stats.service'
import GeneralStatsService from '@/services/general-stats.service'
import BrowserCache from '@/services/browser-cache.js'
import DateRange from '@/components/DateRange/DateRange.vue'
import ImpactCostGeneral from '@/components/Graphs/ImpactCostGeneral/ImpactCostGeneral.vue'
import StatsTitle from '@/components/Stats/StatsTitle/StatsTitle.vue'
import UnsubscribedVoice from '@/components/Stats/Voice/UnsubscribedVoice.vue'
import DeliveredVoice from '@/components/Stats/Voice/DeliveredVoice.vue'
import EventBus from '@/util/EventBus'
import StatsMixin from '../Mixins/StatsMixin'
import StatCard from '@/components/Stats/Card/StatCard.vue'
import { VoiceGraphicData } from '@/classes/stats/VoiceGraphicData'
import { VoiceCalculatedData } from '@/classes/stats/Calculations/Voice/VoiceCalculatedData'
import RadialBarWithLegend from '@/components/Stats/RadialBar/RadialBarWithLegend.vue'
import RadialBar from '@/components/Stats/RadialBar/RadialBar.vue'
import StackedMultiple from '@/components/Stats/StackedColumn/StackedMultiple.vue'
import SimpleBar from '@/components/Stats/SimpleBar/SimpleBar.vue'
import StackedColumn from '@/components/Stats/StackedColumn/StackedColumn.vue'
import UserInteractionVoice from '@/components/Stats/Voice/UserInteractionVoice.vue'
import { SendingStatuses } from '@/models/SendingStatuses'
import { ChannelType } from '@/models/ChannelType'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'

export default {
  name: 'VoiceStats',
  components: {
    HeaderTopDashboard,
    StatsTitle,
    DateRange,
    apexchart: VueApexCharts,
    ImpactCostGeneral,
    UnsubscribedVoice,
    DeliveredVoice,
    StatCard,
    RadialBarWithLegend,
    StackedMultiple,
    SimpleBar,
    StackedColumn,
    RadialBar,
    UserInteractionVoice,
    HeaderTopDashboard,
   },
  mixins: [StatsMixin],
  data: function () {
    return {
      exportForm: new ExportForm(),
      globalControl: new GlobalControl(),
      loading: true,
      options: {},
      content: [],
      searchTerm: '',
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      totalCampaigns: 0,
      chartDataInfo: [],
      attentionCallsPercentage: '0',
      unsubscribedPercentage: '0',
      unsubscribedTotal: 0,
      data: [],
      additional: undefined,
      globalTotalSms: 0,
      currencySymbol: getSymbolFromCurrency(BrowserCache.getJson('user').currencyCode),
      ready: true,
      debouncedInput: '',
      timeout: null,
      impactCost: new ImpactCost(),
      campaginDataSource: {},
      showUnsubscribed: false,
      voiceCalculatedData: new VoiceCalculatedData(),
      cards: [],
      deliveredRatioGraphic: null,
      pulseRatioBarChart: null,
      redGraphicData: null,
      deliveredGraphicData: null,
      readyStats: false,
    }
  },
  computed: {
    hasCampaigns () {
      return this.content.length !== 0
    },
    headers () {
      const headers = [
        { text: this.$t('Envío'), align: 'start', value: 'sending_id', class: 'w-1' },
        { text: this.$t('Campaña'), align: 'start', value: 'campaign_name', class: 'w-12' },
        { text: this.$t('Tipo'), align: 'start', value: 'channel_type_name', class: 'w-9' },
        { text: this.$t('Fecha'), align: 'start', value: 'campaign_date', class: 'w-10' },
        { text: this.$t('Llamadas'), align: 'start', value: 'fixed_sent_total', class: 'w-2' },
        { text: this.$t('Atendidas'), align: 'start', value: 'delivered_total', class: 'w-2' },
        { text: this.$t('No Atendidas'), align: 'start', value: 'fixed_undelivered_total', class: 'w-6' },
        { text: this.$t('Reintentos'), align: 'start', value: 'fixed_calls_retries', class: 'w-4' },
        { text: this.$t('Bajas'), align: 'start', value: 'unsubscribed_total', class: 'w-2' },
      ]

      if (!this.$isSectionsBlockeds('costs')) {
        headers.push(
          {
            text: this.$t('Coste Voz'),
            align: 'start',
            value: 'cost',
            class: 'w-6',
          },
          {
            text: this.$t('Coste Sms'),
            align: 'start',
            value: 'sms_cost',
            class: 'w-6',
          },
          {
            text: this.$t('Total'),
            align: 'start',
            value: 'total',
            class: 'w-6',
          },
        )
      }

      return headers
    },
    params (nv) {
      return {
        ...this.options,
      }
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
        }, 700)
      },
    },
    locale () {
      return this.$i18n.locale
    },
  },
  methods: {
    isBasic (item) {
      return item.channel_type_id === ChannelType.VOICE_ID
    },
    isInteractive (item) {
      return item.channel_type_id === ChannelType.VOICE_INTERACTIVE_ID
    },
    setGraphicsData () {
      const voiceGraphicData = new VoiceGraphicData(this.voiceCalculatedData, this)
      this.cards = voiceGraphicData.getCardsData()
      this.deliveredRatioGraphic = voiceGraphicData.getDeliveredRatioGraphicGraphicData()
      this.redGraphicData = voiceGraphicData.getRedGraphicData()
      this.deliveredGraphicData = voiceGraphicData.getDeliveredGraphicData()
    },

    getColumns () {
      const columns = []
      this.headers.forEach(function (column) {
        columns.push(column.value)
      })
      return columns
    },
    setExportData () {
      this.exportForm.setFromParamsAndColums(this.getParams(), this.getColumns())
    },
    exportAction (urlSuffix) {
      this.setExportData()
      const url = getEnv('VUE_APP_API_URL') + 'api/stats/voice/channel-export-' + urlSuffix + '?' + this.exportForm.getUrlParams()
      window.location.href = url
    },

    exportCsv () {
      this.exportAction('csv')
    },

    exportPdf () {
      this.exportAction('pdf')
    },

    sumReduceByKey (items, key) {
      return items.reduce(
        function (a, b) {
          return a + parseInt(b[key])
        }, 0)
    },

    sumReduceDecimalByKey (items, key) {
      return items.reduce(
        function (a, b) {
          return a + parseFloat(b[key])
        }, 0).toFixed(5)
    },


    calculateFixedTotalCalls (data) {
      if (!data) {
        return 0
      }

      let fixedSentTotal =
      parseInt(data.delivered_total) +
      parseInt(data.rejected_total) +
      parseInt(data.expired_total) +
      parseInt(data.undelivered_total) +
      parseInt(data.unsubscribed_total)
      if (fixedSentTotal < 0) {
        fixedSentTotal = 0
      }
      return fixedSentTotal
    },


    calculateTotalCalls (data) {
      return parseInt(data.total_api) + parseInt(data.total_no_api)
      //return parseInt(data.total_processed)
    },

    calculateUndeliveredTotal (data) {
      return this.calculateTotalCalls(data) - data.delivered_total
    },

    calculateCallRetries (data) {
      let fixedTotalCalls = this.calculateFixedTotalCalls(data)
      fixedTotalCalls = fixedTotalCalls === 0 ? this.calculateTotalCalls(data) : fixedTotalCalls
      const result = fixedTotalCalls - this.calculateTotalCalls(data)
      return result < 0 ? 0 : result
    },

    isApi (data) {
      return data.status_id === SendingStatuses.API
    },

    setTableData (data) {
      const campaignIds = []
      data.forEach(data => {
        data.fixed_sent_total = this.calculateTotalCalls(data)
        data.fixed_undelivered_total = this.calculateUndeliveredTotal(data)
        data.fixed_calls_retries = this.calculateCallRetries(data)
        campaignIds.push(data.campaign_id)
      })
      this.content = data

      const totalSms = this.additional[1]
      if (totalSms) {
        this.globalTotalSms = totalSms.cost != null ? parseFloat(totalSms.cost) : parseFloat('0.000')
      }
    },

    resetReady () {
      this.ready = false
      setTimeout(() => {
        this.ready = true
      })
    },

    // eslint-disable-next-line max-lines-per-function
    setPieChart (totals) {
      if (!totals) {
        return
      }
      totals.fixed_sent_total = this.calculateTotalCalls(totals)
      totals.fixed_undelivered_total = this.calculateUndeliveredTotal(totals)
      totals.delivered_total = parseInt(totals.delivered_total)
      this.resetReady()
    },

    getParams () {
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
        initDate: this.globalControl.initDate,
        endDate: this.globalControl.endDate,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }
      return params
    },
    getDataFromApi () {
      EventBus.$emit('showLoading', true)
      const params = this.getParams()

      this.content = []
      this.loading = true
      VoiceStatsService.getChannelDatatable(params)
        .then(
          (response) => {
            this.readyStats = false
            this.showUnsubscribed = false
            this.campaginDataSource = response
            this.additional = JSON.parse(JSON.stringify(response.additional))
            this.data = JSON.parse(JSON.stringify(response.data))
            this.setTableData(response.data)
            this.itemsPerPage = parseInt(response.per_page)
            this.page = response.current_page
            this.totalCampaigns = response.total
            this.setPieChart(this.additional[0])
            setTimeout(() => {
              this.voiceCalculatedData.calculate(this.campaginDataSource)
              this.setGraphicsData()
              this.showUnsubscribed = true
              this.readyStats = true
            })
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
          EventBus.$emit('showLoading', false)
        })
        this.getimpactCostStats()
    },

    updateImpactCostChartData (data) {
      this.impactCost.series = [
        {
          name: 'Voz',
          color: '#7795FF',
          data: data['voice-interactive'],
        },
      ]
    },
    getimpactCostStats () {
      if (!this.globalControl.initDate || !this.globalControl.endDate) {
        return
      }

      GeneralStatsService.getimpactCostStats({
          initDate: this.globalControl.initDate,
          endDate: this.globalControl.endDate,
          globals: this.globalControl.globals,
          daysOrHour: this.impactCost.daysOrHour,
        }).then((data) => {
          this.updateImpactCostChartData(data)
      })
    },
    selectDates (dates) {
      this.globalControl.initDate = dates[0]
      this.globalControl.endDate = dates[1]
      this.getDataFromApi()
    },
  },
  watch: {
    globalControl: {
      handler: function () {
        this.getDataFromApi()
      },
      deep: true,
    },
    'impactCost.daysOrHour': function (newVal, oldVal) {
      this.getimpactCostStats()
    },
    params: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
    locale () {
      this.setGraphicsData()
    },
    searchTerm () {
      this.options.page = 1
      this.getDataFromApi()
    },
  },
  created () {
  },
  mounted () {
    this.globalControl.globals = false
  },
}
