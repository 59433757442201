import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'ImpactCostGeneral',
  props: ['globalControl', 'impactCost', 'height'],
  components: { apexchart: VueApexCharts },
  data () {
    return {
      heightDef: this.height ? this.height : 'auto',
      switchDiaHoraLabel: 'Por día de la semana',
    }
  },
  watch: {
      globalControl: {
        handler: function () {
          this.updateChartOptions()
        },
        deep: true,
      },
      impactCost: {
        handler: function () {
          this.updateChartOptions()
        },
        deep: true,
      },
  },
  computed: {
    xaxisOptions () {
      return {
        day: [
          this.$t('Lunes'),
          this.$t('Martes'),
          this.$t('Miércoles'),
          this.$t('Jueves'),
          this.$t('Viernes'),
          this.$t('Sábado'),
          this.$t('Domingo'),
        ],
        hour: ['9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00'],
      }
    },
    options () {
      return {
        chart: {
          id: 'vuechart-example',
          type: 'area',
          toolbar: {
            tools: {
              download: false,
            },
          },
        },
        xaxis: {
          categories: [
            this.$t('Lunes'),
            this.$t('Martes'),
            this.$t('Miércoles'),
            this.$t('Jueves'),
            this.$t('Viernes'),
            this.$t('Sábado'),
            this.$t('Domingo'),
          ],
        },
      }
    },
  },
  methods: {
    updateChartOptions () {
      this.switchDiaHoraLabel = this.impactCost.daysOrHour ? this.$t('Por día de la semana') : this.$t('Por hora del día')
      this.options.xaxis.categories = this.impactCost.daysOrHour ? this.xaxisOptions.day : this.xaxisOptions.hour
      setTimeout(() => {
        if (this.$refs.costChart) {
          this.$refs.costChart.updateOptions(this.options, true, true)
        }
      })
    },

  },
  created () {
    this.updateChartOptions()
  },
}
