import { Colors } from '@/classes/colors/Colors'
import VueApexCharts from 'vue-apexcharts'

export default {
  props: ['campaginDataSource', 'campaign', 'height'],
  name: 'UserInteractionVoice',
  components: { apexchart: VueApexCharts },
  data: function () {
    return {
      ready: true,
      heightDef: this.height ? this.height : 220,
    }
  },
  computed: {
    barChartOptions () {
      return {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        annotations: {
          position: 'front',
          yaxis: [{
            label: {
              borderColor: '#c2c2c2',
              text: this.$t('Media del total de los entregados').toString(),
              },
            },
          ],
        },
 
        series: [
          {
            name: this.$t('No Atendidas'),
            data: [],
          },
          {
            name: this.$t('Caducadas'),
            data: [],
          },
          {
            name: this.$t('Bajas'),
            data: [],
          },
        ],
        tooltip: {
          shared: true,
          intersect: false,
        },
        colors: [
          Colors.colorBarOpened,
          Colors.colorBarClicked,
          Colors.colorBarSoftBounced,
          Colors.colorBarHardBounced,
          Colors.colorBarUnsubscribed,
        ],
        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          formatter: function (val) {
              return val
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top',
            },
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value
            },
          },
        },
        xaxis: {
          categories: [],
        },
      }
    },
    locale () {
      return this.$i18n.locale
    },
  },
  watch: {
    locale () {
      this.setBarChart(this.campaginDataSource.data)
    },
  },
  methods: {
    calculateTotalCalls (data) {
      return parseInt(data.total_api) + parseInt(data.total_no_api)
    },

    calculateUndeliveredTotal (data) {
      return this.calculateTotalCalls(data) - data.delivered_total
    },

    setBarChart (data) {
      data.forEach(data => {
        const totalCalls = this.calculateTotalCalls(data)
        const fixedUndeliveredTotal = this.calculateUndeliveredTotal(data)
        const expiredUnique = parseInt(data.expired_unique)
        const unsubscribedTotal = parseInt(data.unsubscribed_total)

        const fixedUndeliveredTotalPercentage = totalCalls ? (fixedUndeliveredTotal / totalCalls).toFixed(1) : 0
        const expiredUniquePercentage = totalCalls ? (expiredUnique / totalCalls).toFixed(1) : 0
        const unsubscribedTotalPercentage = totalCalls ? (unsubscribedTotal / totalCalls).toFixed(1) : 0
        this.barChartOptions.series[0].data.push(fixedUndeliveredTotalPercentage)
        this.barChartOptions.series[1].data.push(expiredUniquePercentage)
        this.barChartOptions.series[2].data.push(unsubscribedTotalPercentage)
        this.barChartOptions.xaxis.categories.push(data.sending_id)
      })
      this.resetReady()
    },

    resetReady () {
      this.ready = false
      setTimeout(() => {
        this.ready = true
      })
    },
  },
  created () {
    this.setBarChart(this.campaginDataSource.data)
  },
}
