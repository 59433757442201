
export default {
  props: ['title', 'subTitle', 'percentage', 'colorClass', 'percentageColorClass'],
  name: 'StatCard',
  components: { },
  data: function () {
   return {
   }
  },
  methods: {

  },
  created () {

  },
}
