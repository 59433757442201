import VueApexCharts from 'vue-apexcharts'

export default {
  props: ['graphicData'],
  name: 'RadialBarWithLegend',
  components: { apexchart: VueApexCharts },
  data: function () {
   return {
   }
  },
  computed: {
    chartOptions () {
      const graphicData = this.graphicData
      return {
        chart: {
          height: graphicData.chartHeight,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 255,
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent',
              image: undefined,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        colors: graphicData.colors,
        labels: graphicData.labels,
        legend: {
          show: true,
          floating: true,
          fontSize: graphicData.legendProps.fontSize,
          position: 'left',
          offsetX: graphicData.legendProps.offsetX,
          offsetY: graphicData.legendProps.offsetY,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0,
          },
          formatter: function (seriesName, opts) {
            return seriesName + ':  ' + opts.w.globals.series[opts.seriesIndex] + '%'
          },
          itemMargin: {
            vertical: 1,
          },
        },
        responsive: [{
          breakpoint: 280,
          options: {
            legend: {
                show: false,
            },
          },
        }],
      }
    },
  },
  methods: {

  },
  mounted () {

  },
}
