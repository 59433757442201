import VueApexCharts from 'vue-apexcharts'
import { Colors } from '@/classes/colors/Colors'

export default {
  props: ['campaginDataSource'],
  name: 'DeliveredVoice',
  components: { apexchart: VueApexCharts },
  data: function () {
    return {
      ready: true,
      colors: Colors,
      attentionCallsPercentage: '0',
      unsubscribedPercentage: '0',
      unsubscribedTotal: 0,
      series: [],
      chartDataInfo: [],
      chartOptions: {
        legend: {
          show: false,
        },
        fill: {
          colors: [Colors.colorChartDelivered, Colors.colorChartUndelivered],
        },
        labels: [this.$t('Atendidas').toString(), this.$t('No Atendidas').toString()],
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
            },
          },
        }],
      },
      showEmpty: false,
      emptySeries: [100],
      emptyChartOptions: {
        labels: [''],
        legend: {
          show: false,
        },
        fill: {
          colors: [Colors.colorChartEmptyBar],
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: '16px',
                color: undefined,
                offsetY: 120,
              },
              value: {
                offsetY: 76,
                fontSize: '22px',
                color: undefined,
                formatter: function (val) {
                  return '' // val + '%'
                },
              },
            },
          },
        },
      },
    }
  },
  methods: {
    calculateTotalCalls (data) {
      let fixedSentTotal = parseInt(data.delivered_total) + parseInt(data.rejected_total) + parseInt(data.expired_total) + parseInt(data.undelivered_total)
      if (fixedSentTotal < 0) {
        fixedSentTotal = 0
      }
      return fixedSentTotal
    },

    calculateUndeliveredTotal (data) {
      let fixedUndeliveredTotal = parseInt(data.rejected_total) + parseInt(data.undelivered_total)
      if (fixedUndeliveredTotal < 0) {
        fixedUndeliveredTotal = 0
      }
      return fixedUndeliveredTotal
    },

    // eslint-disable-next-line max-lines-per-function
    setPieChart (totals) {
      totals.fixed_sent_total = this.calculateTotalCalls(totals)
      totals.fixed_undelivered_total = this.calculateUndeliveredTotal(totals)
      totals.delivered_total = parseInt(totals.delivered_total)
      this.series = [totals.delivered_total, totals.fixed_undelivered_total]
      const totalCalls = totals.delivered_total + totals.fixed_undelivered_total
      const undeliveredPercentage = (totalCalls && totalCalls !== 0) ? ((totals.fixed_undelivered_total / totalCalls) * 100) : 0
      this.chartDataInfo = [
        {
          label: this.$t('No atendidas').toString(),
          value: undeliveredPercentage.toFixed(1) + '%',
          color: Colors.colorChartUndelivered,
        },
      ]

      const attentionCallsPercentage = totals.fixed_sent_total === 0 ? totals.fixed_sent_total : ((totals.delivered_total / totalCalls) * 100)
      this.attentionCallsPercentage = attentionCallsPercentage.toFixed(1)
      const unsubscribedPercentage = (totals.unsubscribed_total / totals.delivered_total) * 100
      this.unsubscribedPercentage = unsubscribedPercentage > 0 ? unsubscribedPercentage.toFixed(1) : '0'
      this.unsubscribedTotal = parseInt(totals.unsubscribed_total)

      this.chartOptions.colors = [Colors.colorChartDelivered, Colors.colorChartUndelivered]
      this.chartOptions.labels = [this.$t('Atendidas').toString() + ' ' + attentionCallsPercentage.toFixed(1) + '%', this.$t('No atendidas').toString() + ' ' + undeliveredPercentage.toFixed(1) + '%']
      this.resetReady()
    },

    resetReady () {
      this.ready = false
      setTimeout(() => {
        this.ready = true
      })
    },
  },
  created () {
    const additional = JSON.parse(JSON.stringify(this.campaginDataSource.additional))
    if (this.campaginDataSource.total > 0) {
      this.setPieChart(additional[0])
    } else {
      this.showEmpty = true
    }
  },
}
