import { GraphicData } from '@/classes/stats/GraphicData'
import { Colors } from '@/classes/colors/Colors'

export class VoiceGraphicData {
  voiceCalculatedData = null
  thatContext = null

  constructor (voiceCalculatedData, thatContext) {
    this.voiceCalculatedData = voiceCalculatedData
    this.thatContext = thatContext
  }

  getCardsData () {
    return [
      {
        title: this.thatContext.$t('Llamadas').toString(),
        subTitle: this.voiceCalculatedData.totals.fixed_sent_total,
        percentage: 100,
        colorClass: 'gradient-standard',
        percentageColorClass: 'blue',
        cols: 3,
      },
      {
        title: this.thatContext.$t('Atendidas').toString(),
        subTitle: this.voiceCalculatedData.totals.delivered_total,
        percentage: parseFloat(this.voiceCalculatedData.deliveredPercentage).toFixed(0),
        colorClass: 'gradient-success',
        percentageColorClass: 'green',
        cols: 3,
      },
      {
        title: this.thatContext.$t('No Atendidas').toString(),
        subTitle: this.voiceCalculatedData.totals.fixed_undelivered_total,
        percentage: parseFloat(this.voiceCalculatedData.undeliveredPercentage).toFixed(0),
        colorClass: 'gradient-alert',
        percentageColorClass: 'alert',
        cols: 3,
      },
      {
        title: this.thatContext.$t('Bajas').toString(),
        subTitle: this.voiceCalculatedData.totals.unsubscribed_total,
        percentage: parseFloat(this.voiceCalculatedData.unsubscribedPercentage).toFixed(0),
        colorClass: 'gradient-danger',
        percentageColorClass: 'danger',
        cols: 3,
      },
    ]
  }

  getDeliveredRatioGraphicGraphicData () {
    let totalCalls = this.voiceCalculatedData.totals.fixed_sent_total
    totalCalls = totalCalls || 0

    let deliveredTotal = this.voiceCalculatedData.totals.delivered_total
    deliveredTotal = deliveredTotal || 0

    let undeliveredTotal = this.voiceCalculatedData.totals.fixed_undelivered_total
    undeliveredTotal = undeliveredTotal || 0

    return new GraphicData(
      this.thatContext.$t('Ratio de Atendidas'),
      [
        {
          label: this.thatContext.$t('Llamadas').toString(),
          value: '<b>' + totalCalls + '</b> / ',
          total: totalCalls,
        },
        {
          label: this.thatContext.$t('Atendidas').toString(),
          value: '<b>' + deliveredTotal + '</b> / ',
          total: totalCalls,
        },
        {
          label: this.thatContext.$t('No Atendidas').toString(),
          value: '<b>' + undeliveredTotal + '</b> / ',
          total: totalCalls,
        },
      ],
      [100, this.voiceCalculatedData.deliveredPercentage, this.voiceCalculatedData.undeliveredPercentage],
      [this.thatContext.$t('Llamadas').toString(), this.thatContext.$t('Atendidas').toString(), this.thatContext.$t('No Atendidas').toString()],
      this.voiceCalculatedData.deliveredPercentage,
      this.thatContext.$t('Atendidas').toString(),
      deliveredTotal,
      0,
      true,
      300,
      [
        Colors.statistics.SENT_TOTAL,
        Colors.statistics.DELIVERED_TOTAL,
        Colors.statistics.UNDELIVERED_TOTAL,
      ],
    )
  }

  getTypeData (type) {
    switch (type) {
      case 'PlayAndSendSms':
        return {
          label: this.thatContext.$t('Enviar SMS').toString(),
          color: Colors.statistics.PLAY_AND_SEND_SMS,
        }
      case 'CallTransfer':
        return {
          label: this.thatContext.$t('Transferir llamada').toString(),
          color: Colors.statistics.CALL_TRANSFER,
        }
      case 'GetDataWhilePlaying':
        return {
          label: this.thatContext.$t('Acceder a submenú').toString(),
          color: Colors.statistics.GET_DATA_WHILE_PLAYING,
        }
      case 'Play':
        return {
          label: this.thatContext.$t('Reproducir mensaje').toString(),
          color: Colors.statistics.PLAY,
        }
      case 'RepeatMessage':
        return {
          label: this.thatContext.$t('Repetir Mensaje').toString(),
          color: Colors.statistics.REPEAT_MESSAGE,
        }
      case 'AddToBlackList':
        return {
          label: this.thatContext.$t('Añadir a Blacklist').toString(),
          color: Colors.statistics.ADD_TO_BLACKLIST,
        }
    }
  }

  getPulseRatioBarChartGraphicData (pressedKeysData) {
    const mainLevelTotal = pressedKeysData.totalPressedKeys
    const labels = []
    const firstLevelPercentages = []
    const legend = []
    const colors = []
    for (const key in pressedKeysData.optionsArray) {
      labels.push(this.getTypeData(pressedKeysData.optionsArray[key].type).label)
      firstLevelPercentages.push(pressedKeysData.optionsArray[key].percentage)
      legend.push({
        label: this.getTypeData(pressedKeysData.optionsArray[key].type).label,
        value: '',
        total: pressedKeysData.optionsArray[key].totalPressedKeys,
      })
      colors.push(this.getTypeData(pressedKeysData.optionsArray[key].type).color)
    }

    const interactionPercentage = mainLevelTotal ? ((mainLevelTotal / this.voiceCalculatedData.totals.delivered_total) * 100).toFixed(0) : 0

    return new GraphicData(
      this.thatContext.$t('Ratio de Interacciones'),
      legend,
      firstLevelPercentages,
      labels,
      interactionPercentage,
      this.thatContext.$t('Interacciones'),
      mainLevelTotal,
      0,
      true,
      300,
      colors,
    )
  }

  getNewPulseRatioBarChartGraphicData (newPressedKeysData) {
    let mainLevelTotal = 0
    const labels = []
    const firstLevelPercentages = []
    const legend = []
    const colors = []

    const homeSectionOptions = newPressedKeysData.filter((option) => option.sectionName === 'Home')

    for (const key in homeSectionOptions) {
      const option = homeSectionOptions[key]
      const typeData = this.getTypeData(option.type)
      const label = typeData.label
      const color = typeData.color
      labels.push(label)
      firstLevelPercentages.push(option.percentage)

      legend.push({
        label: label,
        value: '',
        total: option.keyPressTotal,
      })
      colors.push(color)
      mainLevelTotal = option.totalSection
    }

    let interactionPercentage = (mainLevelTotal && this.voiceCalculatedData.totals.delivered_total) ? ((mainLevelTotal / this.voiceCalculatedData.totals.delivered_total) * 100).toFixed(0) : 0
    interactionPercentage = interactionPercentage >= 100 ? 100 : interactionPercentage

    return new GraphicData(
      this.thatContext.$t('Ratio de Interacciones'),
      legend,
      firstLevelPercentages,
      labels,
      interactionPercentage,
      this.thatContext.$t('Interacciones'),
      mainLevelTotal,
      0,
      true,
      300,
      colors,
    )
  }

  getRedGraphicData () {
    return new GraphicData(
      '',
      [],
      [this.voiceCalculatedData.totals.fixed_undelivered_total, this.voiceCalculatedData.totals.unsubscribed_total],
      [this.thatContext.$t('No Atendidas').toString(), this.thatContext.$t('Bajas').toString()],
      0,
      '',
      0,
      0,
      true,
      250,
      [
        Colors.statistics.UNDELIVERED_TOTAL,
        Colors.statistics.UNSUBSCRIBED_TOTAL,
      ],
    )
  }

  getDeliveredGraphicData () {
    return new GraphicData(
        '',
        [],
        [this.voiceCalculatedData.totals.fixed_sent_total, this.voiceCalculatedData.totals.delivered_total, this.voiceCalculatedData.totals.fixed_undelivered_total],
        [this.thatContext.$t('Llamadas').toString(), this.thatContext.$t('Atendidas').toString(), this.thatContext.$t('No Atendidas').toString()],
        0,
        '',
        0,
        0,
        true,
        200,
        [
          Colors.statistics.SENT_TOTAL,
          Colors.statistics.DELIVERED_TOTAL,
          Colors.statistics.UNDELIVERED_TOTAL,
        ],
      )
    }
}
