/* eslint-disable no-magic-numbers */
export class SendingStatuses {
    static PENDING = 1
    static SAVED = 2
    static SENDING = 3
    static PAUSED = 4
    static FINISHED = 5
    static CANCELLED = 6
    static ENQUEUING = 7
    static EDITING = 8
    static PREPARING = 9
    static OPENED = 10
    static API = 10
    static AUTOMATED = 11
    static WAITING = 12
    static IMPORTING = 13
    static PREPARING_IMPORT = 14
    static VALIDATING = 15
    static VALIDATED = 16
    static TEST = 99
}
